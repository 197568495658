<template>
  <div>
    <ParallaxImageTitle :imagePath="require('@/assets/images/www/cards/9-1400.jpg')" title="Tailwheel Endorsement" subtitle="Challenge yourself" />
    <div class="program-description">
      <h1>What is a Tailwheel Endorsement?</h1>
      <p>This fun and challenging endorsement allows you to fly tailwheel aircraft.</p>
    </div>
  </div>
</template>

<script>
import ParallaxImageTitle from '@/components/ParallaxImageTitle.vue'

export default {
  components: {
    ParallaxImageTitle
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';
@include program-page;
</style>
